import { SupportedChainId } from 'constants/chains'

/**
 * Map of supported chains to USDC contract addresses
 */
export const CHAIN_IDS_TO_USDC_ADDRESSES = {
  // [SupportedChainId.ETH]: '0x1c7d4b196cb0c7b01d743fbc6116a902379c7238',
  [SupportedChainId.ETH]: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',

  // [SupportedChainId.AVAX]: '0x5425890298aed601595a70AB815c96711a31Bc65',
  [SupportedChainId.AVAX]: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',

  // [SupportedChainId.ARB]: '0x75faf114eafb1bdbe2f0316df893fd58ce46aa4d',
  [SupportedChainId.ARB]: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',

  [SupportedChainId.NOBLE]: 'uusdc',
}

/**
 * Map of supported chains to Token Messenger contract addresses
 */
export const CHAIN_IDS_TO_TOKEN_MESSENGER_ADDRESSES = {
  // [SupportedChainId.ETH_SEPOLIA]: '0x9f3b8679c73c2fef8b59b4f3444d4e156fb70aa5',
  [SupportedChainId.ETH]: '0xbd3fa81b58ba92a82136038b25adec7066af3155',

  // [SupportedChainId.AVAX_FUJI]: '0xeb08f243e5d3fcff26a9e38ae5520a669f4019d0',
  [SupportedChainId.AVAX]: '0x6b25532e1060ce10cc3b0a99e5683b91bfde6982',

  // [SupportedChainId.ARB_SEPOLIA]: '0x9f3b8679c73c2fef8b59b4f3444d4e156fb70aa5',
  [SupportedChainId.ARB]: '0x19330d10D9Cc8751218eaf51E8885D058642E08A',

  [SupportedChainId.NOBLE]: 'uusdc',
}

/**
 * Map of supported chains to Message Transmitter contract addresses
 */
export const CHAIN_IDS_TO_MESSAGE_TRANSMITTER_ADDRESSES = {
  // [SupportedChainId.ETH_SEPOLIA]: '0x7865fafc2db2093669d92c0f33aeef291086befd',
  [SupportedChainId.ETH]: '0x0a992d191deec32afe36203ad87d7d289a738f81',

  // [SupportedChainId.AVAX_FUJI]: '0xa9fb1b3009dcb79e2fe346c16a604b8fa8ae0a79',
  [SupportedChainId.AVAX]: '0x8186359af5f57fbb40c6b14a588d2a59c0c29880',

  // [SupportedChainId.ARB_SEPOLIA]: '0xacf1ceef35caac005e15888ddb8a3515c41b4872',
  [SupportedChainId.ARB]: '0xC30362313FBBA5cf9163F0bb16a0e01f01A896ca',

  [SupportedChainId.NOBLE]: 'uusdc',
}
